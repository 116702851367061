.add {
  &-form {
    height: calc(100vh - 350px);
    overflow-y: auto;

    @media only screen and (max-width: 867px) {
      height: calc(100vh - 400px);
    }
  }

  &-fields {
    margin: 0 15px;
  }

  &-election {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: var(--green-t3);
    display: flex;
    max-width: max-content;
    margin: 10px 0 0 35px;
  }
}
