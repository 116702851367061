.login {
  &-wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
    transition: 200ms;
    z-index: 9999;

    &.logging-in {
      backdrop-filter: blur(10px);
    }
  }

  &-logo {
    position: relative;
    width: 100px;
    margin: 15px auto 35px;

    & img {
      width: 100%;
    }
  }

  &-view {
    position: relative;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    z-index: 2;
  }

  &-box {
    position: relative;
    vertical-align: middle;
    width: 500px;
    box-sizing: border-box;
    padding: 80px 70px;
    background: var(--white);
    border-radius: var(--component-radius);
    box-shadow: var(--component-shadow);

    @media only screen and (max-width: 867px) {
      padding: 50px;
    }

    &.warning {
      padding: 50px 70px 15px;
    }
  }

  .logging-in &-fields {
    display: none;
  }
}

.logo {
  &-sub {
    font-size: 11px;
    font-weight: 200;
    text-transform: uppercase;
    color: var(--dark-grey);
    margin-top: 7px;
    text-align: center;
    width: 100%;

    &.user-space {
      margin-bottom: 15px;
    }
  }

  &-large {
    font-size: 27px;
    font-weight: 300;
    padding: 0 0 25px;
  }
}

.blur-frame {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  transition: 400ms;
  z-index: 1;
}

.eye-icon {
  position: absolute;
  opacity: 0.5;
  right: 0;
  top: 50%;
  margin: -12px 15px 0 0;
  cursor: pointer;

  & img {
    width: 25px;
  }
}
