.input {
  position: relative;
  width: 100%;
  margin: 7px auto 12px;

  &-icon {
    position: absolute;
    left: 0;
    top: 45%;
    width: 20px;
    margin: -10px 0 0 15px;
    opacity: 0.5;
  }

  & input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 15px 15px 55px;
    border-radius: var(--component-radius);
    box-shadow: var(--input-shadow);
    border: 1px var(--lighter-grey) solid;
    background: var(--white);
    font-size: 18px;
    transition: all 0.3s ease;

    &:active,
    &:focus,
    &:focus-visible {
      border: 1px solid var(--dark-grey);
    }

    &:disabled {
      border: 1px var(--lighter-grey) solid;
    }

    &.edit {
      border: 1px var(--blue-t4) dashed;
    }
  }
}
