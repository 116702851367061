.platform-frame {
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 2;

  & .ps__rail-y,
  & .ps__rail-x {
    opacity: 1;
  }

  & .ps__rail-y:hover > .ps__thumb-y,
  & .ps__rail-y:focus > .ps__thumb-y,
  & .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 8px;
    background-color: #79747e;
  }

  & .ps__rail-x:hover > .ps__thumb-x,
  & .ps__rail-x:focus > .ps__thumb-x,
  & .ps__rail-x.ps--clicking .ps__thumb-x {
    height: 8px;
    background-color: #79747e;
  }

  & .ps .ps__rail-y:hover,
  & .ps .ps__rail-y:focus,
  & .ps .ps__rail-y.ps--clicking,
  & .ps .ps__rail-x:hover,
  & .ps .ps__rail-x:focus,
  & .ps .ps__rail-x.ps--clicking {
    background: transparent;
  }
}

.pf-left {
  vertical-align: top;
  position: relative;
  background: var(--white);
  box-shadow: var(--component-shadow);
  overflow: hidden;
  position: fixed;
  height: 100vh;
  width: var(--dashboard-menu-width);
  z-index: 9998;

  @media only screen and (max-width: 1967px) {
    width: 250px;
  }

  @media only screen and (max-width: 1440px) {
    width: 175px;
  }

  @media only screen and (max-width: 867px) {
    display: none !important;
  }

  & .logo {
    position: relative;
    width: 135px;
    margin: 70px auto 25px;

    & img {
      width: 100%;
    }
  }
}

.pf-right {
  vertical-align: top;
  position: relative;
  box-shadow: var(--component-shadow);
  overflow: hidden;
  width: var(--dashboard-main-width);
  margin-left: var(--dashboard-menu-width);
  background: var(--lighter-grey);
  padding: 90px 50px 0;
  min-height: 100vh;

  @media only screen and (max-width: 1967px) {
    margin-left: 250px;
    width: calc(100% - calc(250px + 25px));
  }

  @media only screen and (max-width: 1440px) {
    margin-left: 175px;
    width: calc(100% - calc(175px + 10px));
  }

  @media only screen and (max-width: 867px) {
    width: 100%;
    margin: 0 auto;
    padding: 90px 10px;
  }
}

.pfr-header {
  padding: 25px 50px 0;
  width: 100%;
  background: var(--lighter-grey);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 100;

  &.fixed {
    padding: 25px 75px 25px calc(var(--dashboard-menu-width) + 50px);
    box-shadow: var(--component-shadow);
    background: transparent;
    backdrop-filter: blur(10px);

    @media only screen and (max-width: 1967px) {
      padding-left: 300px;
    }

    @media only screen and (max-width: 1440px) {
      padding: 25px 60px 25px 225px;
    }

    @media only screen and (max-width: 867px) {
      padding: 25px 50px;
    }
  }
}

.dashboard {
  &-title {
    font-size: 36px;
    font-weight: 900;
    color: var(--dark-grey);
    width: 60%;

    @media only screen and (max-width: 1120px) {
      font-size: 22px;
    }
  }

  &-menu {
    width: 40%;
    text-align: right;
  }
}

.dm-left {
  font-size: 14px;
  padding-right: 25px;
  width: 47px;

  & span {
    display: block;

    & div {
      height: 2px;
      background: var(--dark-grey);
      margin: 2px auto;
    }
  }
}

.menu-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  font-size: 9px;
  color: var(--dark-grey);
  border-top: 1px var(--light-grey) solid;
  background: var(--lighter-grey);
}

.user-icon {
  position: relative;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  border: 3px var(--dark-grey) solid;
  z-index: 16;

  &:hover {
    cursor: pointer;
  }

  & img {
    width: 18px;
  }

  & .notify-num {
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    top: -3px;
    right: -3px;
    padding: 1px 1px 0 2px;
    border-radius: 50%;
    background: var(--red-t5);
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: #fff;
  }

  & img {
    opacity: 0.8;
  }
}

.disclaimer {
  display: block;
  padding-bottom: 5px;
}

.platform-footer {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px 0;
}

.version-num {
  position: relative;
  display: inline-block;
  margin: 0;
  color: var(--white);
  font-size: 7px;
  text-align: center;
  font-weight: 200;
  background: var(--green-t5);
  border-radius: 5px;
  width: 50px;
  padding: 3px 0;
}

.background-elem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(7, 51, 111);
  background: linear-gradient(
    34deg,
    rgba(7, 51, 111, 1) 0%,
    rgba(11, 66, 142, 1) 19%,
    rgba(95, 0, 77, 1) 34%,
    rgba(175, 18, 145, 1) 43%,
    rgba(55, 110, 186, 1) 54%,
    rgba(7, 51, 111, 1) 100%
  );
  background-attachment: fixed;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  z-index: 0;
}
