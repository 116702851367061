.upload-instructions {
  padding: 5px 0 10px;
  font-size: 12px;
  color: var(--dark-grey);
}

#file-upload1,
#file-upload2,
#file-upload-multiple {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.upload {
  margin: 30px 15px 0;

  &-label {
    font-size: 16px;
    font-weight: 600;
  }

  &-filename {
    font-weight: 400;
  }

  &-btns {
    margin-top: 10px;

    & .edit-btn:first-of-type {
      margin-right: 10px;
    }
  }

  &-selector {
    position: relative;
    margin: 0 0 15px;

    & select {
      width: auto;
      position: relative;
      font-size: 16px;
      border-radius: var(--component-radius-secondary);
      padding: 7px 15px;
      border: 1px var(--accent-grey) solid;
    }
  }

  &-details {
    text-align: left;
    border-bottom: 1px var(--accent-grey) solid;
    padding: 0 0 10px;
  }

  &-btn {
    position: relative;
    width: 100%;
    text-align: right;
    padding: 15px 0;
  }

  &-box {
    position: relative;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    border: 1px var(--accent-grey) dashed;
    margin-bottom: 15px;
  }
}

.details-item {
  text-align: left;
  border-bottom: 1px var(--accent-grey) solid;
}

.di {
  &-label,
  &-value {
    display: inline-block;
    vertical-align: top;
    padding: 7px 0;
    box-sizing: border-box;
  }

  &-label {
    font-size: 12px;
    width: 100px;
  }

  &-value {
    width: calc(100% - 100px);
    font-size: 12px;
    padding-left: 25px;
  }
}

.component-frame {
  position: relative;
  box-shadow: var(--component-shadow-secondary);
  border-radius: var(--component-radius-secondary);
  overflow: hidden;
  padding: 25px 50px;
  box-sizing: border-box;
}

.edit-btn.cancel {
  margin-right: 10px;
  background: var(--red-t4);

  &:hover {
    background: var(--red-t5);
  }
}
