.custom-select {
  position: relative;
  width: 100%;
  font-size: 18px;

  &.pagination {
    display: inline-block !important;
    width: auto;
    font-size: 12px;
  }
}

.selected-option {
  padding: 15px 15px 15px 55px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: var(--component-radius);
  box-shadow: var(--input-shadow);
  border: 1px var(--lighter-grey) solid;
  color: #000;
  transition: all 0.3s ease;

  .pagination & {
    padding: 7px 10px;
    transition: none;
  }

  .opened & {
    border-radius: 25px 25px 0 0;
    border: 1px solid var(--dark-grey);
  }

  .pagination.opened & {
    border-radius: 0 0 17px 17px;
    border: 1px var(--lighter-grey) solid;
  }

  &__name {
    margin: 0;

    .pagination & {
      padding: 0;
    }

    &.default {
      color: #a9a9a9;
    }
  }
}

.options {
  &-wrapper {
    background-color: #fff;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    border-radius: 0 0 25px 25px;
    box-shadow: var(--component-shadow);
    height: 153px;

    .pagination & {
      border-radius: 17px 17px 0 0;
      height: 84px;
      top: -84px;
    }
  }

  &-list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    & li {
      padding: 12px 12px 12px 55px;
      cursor: pointer;
      transition: all 0.3s ease;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;

      .pagination & {
        padding: 5px 10px;
      }

      &:hover {
        background-color: var(--lighter-grey);
      }
    }
  }
}

.arrow {
  transition: transform 0.2s;
  margin: 0;

  .pagination & {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }

  &.rotated {
    transform: rotate(180deg);
  }
}
