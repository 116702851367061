.menu {
  position: relative;
  width: 100%;

  &-topic {
    position: relative;
    width: 90%;
    box-sizing: border-box;
    margin: 10px auto;
    padding: 7px 10px 7px 0;
    transition: 200ms;
    border-radius: var(--component-radius-secondary);
    box-shadow: var(--component-shadow);

    @media only screen and (max-width: 1440px) {
      padding: 7px 5px;
    }

    &:hover {
      background: var(--blue-t3);
      cursor: pointer;
      color: var(--white);
    }

    &-icon {
      width: 70px;
      text-align: center;
      opacity: 0.4;
      padding-top: 3px;

      @media only screen and (max-width: 1967px) {
        width: 45px;
      }

      @media only screen and (max-width: 1440px) {
        width: 15px;
      }

      .menu-topic:hover & {
        opacity: 1;
      }

      & img {
        width: 15px;

        @media only screen and (max-width: 1440px) {
          width: 8px;
        }
      }
    }

    &-go {
      padding-top: 1px;
      width: 7px;
      opacity: 0.4;
      margin-left: -8px;
      transition: 400ms;

      .menu-topic:hover & {
        opacity: 1;
        margin-left: 0;
      }
    }

    &-label {
      width: calc(100% - 80px);
      font-size: 12px;
      font-weight: 300;

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }
}
