.dashboard-components {
  text-align: left;
}

.section-components.top-space {
  padding-top: 50px;
}

.chart-component {
  border-radius: var(--component-radius);
  box-shadow: var(--component-shadow);
  padding: 15px 35px;
  vertical-align: top;
  transition: 200ms;
  background: var(--white);

  @media only screen and (max-width: 867px) {
    margin: 15px auto;
    overflow-x: auto;
  }

  &:hover {
    box-shadow: var(--component-shadow-hover);
  }
}

.sentiment-source {
  padding-bottom: 25px;
  width: calc(50% - 15px);

  @media only screen and (max-width: 1120px) {
    display: block;
    width: 100%;
    margin: 15px auto;
    box-sizing: border-box;
  }

  &.full-width {
    width: 100%;
  }
}

.sentiment-charts {
  text-align: center;

  &.sync-search {
    margin-bottom: 35px;
  }
}

.chart-title {
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 400;
  color: var(--dark-grey);

  &.align-left {
    text-align: left;
  }

  & img {
    vertical-align: middle;
    opacity: 0.5;
    width: 20px;
    margin-right: 15px;
  }
}

.sentiment-trend {
  margin: 25px 0 0 0;
}

.dc-left {
  vertical-align: top;
  width: calc(100% - 375px);
  margin-right: 25px;

  &.full {
    width: 100%;
  }

  @media only screen and (max-width: 1967px) {
    width: calc(100% - 300px);
  }

  @media only screen and (max-width: 1367px) {
    display: block;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

.tt-right {
  width: 350px;
  text-align: right;
}

.tt-left {
  width: calc(100% - 350px);
}

.search {
  &-selection {
    position: relative;
    width: 100%;
    padding: 15px 0;
    text-align: left;
  }

  &Form span {
    font-size: 14px;
    line-height: 27px;
    margin: 25px 0;
  }
}

.ss {
  &-object {
    position: absolute;
    margin: 0;
    width: 250px;
    border-radius: var(--component-radius);
    box-shadow: var(--component-shadow);
    background: var(--white);
    z-index: 99;
    overflow: hidden;
    color: var(--dark-grey);
    font-size: 14px;

    @media only screen and (max-width: 867px) {
      z-index: 10;
    }

    & span {
      display: block;
      padding: 15px 20px;
      width: 100%;
      box-sizing: unset;
      transition: 400ms;

      &:hover {
        cursor: pointer;
        background: var(--lighter-grey);
      }

      & img {
        width: 15px;
        opacity: 0.3;
        margin-right: 15px;
        vertical-align: middle;
      }
    }

    &.open {
      & span:first-child {
        border-bottom: 1px var(--accent-grey) solid;
      }

      & .ss-dropdown span:first-child {
        border-bottom: 0;
      }
    }
  }

  &-dropdown {
    display: block;

    &-list {
      position: relative;
      display: block;
      height: 258px;

      &.n1 {
        height: 52px;
      }

      &.n2 {
        height: 103px;
      }

      &.n3 {
        height: 155px;
      }

      &.n4 {
        height: 206px;
      }
    }

    & span {
      display: block;

      & img {
        width: 15px;
        opacity: 0.3;
        margin-right: 15px;
        vertical-align: middle;
      }

      &.create-new {
        background: var(--blue-t3);
        color: var(--white);

        &:hover {
          background: var(--blue-t4);
        }

        & input {
          background: var(--white);
          border: 0;
          padding: 7px 15px;
          box-sizing: border-box;
          border-radius: var(--component-radius);
          box-shadow: var(--component-shadow);
          width: 130px;
        }

        & i {
          text-decoration: none;
          font-style: normal;
          margin: 0 0 0 7px;
          padding: 8px 10px;
          background: var(--blue-t5);
          box-shadow: var(--component-shadow);
        }

        & input,
        & i {
          border-radius: 8px;
        }
      }
    }
  }
}

.creating-source-fader {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 15px;
  text-align: center;
}

.edit-btn {
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: var(--component-radius);
  box-shadow: var(--input-shadow);
  transition: 400ms;
  display: inline-block;
  text-align: center;
  padding: 10px 25px;
  box-sizing: border-box;
  background: var(--blue-t3);
  color: var(--white);
  cursor: pointer;

  &:hover {
    background: var(--blue-t5);
  }

  &.save {
    background: var(--green-t2);

    &:hover {
      background: var(--green-t3);
    }
  }

  &-cancel {
    padding: 0 25px;
    font-size: 11px;
    color: var(--dark-grey);
    transition: 400ms;

    &:hover {
      cursor: pointer;
      color: var(--green-t5);
    }
  }
}
