.notify {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 11000;
  background: var(--accent-grey);
  border-left: 3px var(--dark-grey) solid;
  padding: 15px 25px;
  box-sizing: border-box;
  box-shadow: var(--component-shadow);

  &.success {
    background: var(--green-t4);
    border-left: 3px var(--green-t5) solid;
    color: var(--white);
  }

  &.error {
    background: var(--red-t4);
    border-left: 3px var(--red-t5) solid;
    color: var(--white);
  }

  &-title {
    font-size: 12px;
    text-transform: uppercase;
    opacity: 0.7;
    padding-bottom: 7px;
  }

  &-message {
    font-size: 12px;
  }
}
