.user {
  &-profile {
    padding-bottom: 35px;

    &-edits {
      text-align: right;

      & .edit-btn {
        margin-right: 5px;
      }
    }

    &-left {
      width: 200px;

      & span {
        position: relative;
        width: 80px;
        text-transform: uppercase;
        background: var(--blue-t3);
        color: var(--white);
        display: block;
        margin-top: -25px;
        font-size: 11px;
        padding: 7px 15px;
        border-radius: 10px;
      }
    }

    &-right {
      width: calc(100% - 200px);
      text-align: left;
    }

    &-icon {
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      padding: 30px 35px;
      border: 5px var(--dark-grey) solid;
      border-radius: 50%;
      display: flex;

      & img {
        width: 100%;
        opacity: 0.75;
      }
    }

    &-name {
      font-size: 27px;

      & span {
        margin: 0 0 0 5px;
        background: var(--green-t1);
        font-size: 12px;
        vertical-align: top;
        padding: 7px 10px;
        border-radius: var(--component-radius-secondary);
        color: var(--white);
      }
    }

    &-data {
      padding: 25px 0 0;
    }
  }

  &-info-item {
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    margin-right: 75px;
  }
}

.uii-top {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 100;
  padding-bottom: 5px;
}

.edit-btn.warning {
  margin-right: 25px;
  background: var(--red-t4);

  &:hover {
    background: var(--red-t5);
  }
}
